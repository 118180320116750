.footer__container {
  position: sticky;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


}

.copyright__title {
  font-size: 2vw;
  font-family: sans-serif;
}

.footer__box__widget {
  margin-bottom: -1vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.noveltylogo {
  width: 20%;
}

@media screen and (max-width: 992px) {
  .copyright__title {
    font-size: 3vw;
    font-family: sans-serif;
  }
}


@media screen and (max-width: 768px) {
  .copyright__title {
    font-size: 3vw;
    font-family: sans-serif;
  }
}

@media screen and (max-width: 550px) {
  .copyright__title {
    font-size: 5vw;
    font-family: sans-serif;
  }

  .noveltylogo {
    width: 30%;
  }

}