.home-container {
  overflow-y: scroll;
}

.novelty__n {
  width: 10%;
}


.home__main__elements {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.home__text__box {
  margin-top: -20vh;
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 30vh;

}

.home__title {
  color: white;
  font-weight: bold;
  font-size: 9vw;
  font-family: sans-serif;
  position: relative;
}

.home__slogan {
  color: white;
  font-weight: bold;
  margin-top: 0.5rem;
  font-size: 3vw;
  font-family: sans-serif;
}

.construction__box {
  margin-top: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  width: 50vw;

}

.construction__text {
  font-size: 5vw;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  z-index: 1;
}





.rbutton {
  text-shadow: 0 0 500px #6d67e4, 0 0 50px #6d67e4, 0 0 10px #6d67e4, 0 0 100px #6d67e4, 0 0 100px #7743db;
}




/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
  .home__text__box {
    margin-top: -14vh;
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 30vh;

  }

  .home__title {
    color: white;
    font-weight: bold;
    font-size: 12vw;
    font-family: sans-serif;
  }

  .home__slogan {
    color: white;
    font-weight: bold;
    margin-top: 0.5rem;
    font-size: 4vw;
    font-family: sans-serif;
  }

  .construction__box {
    margin-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    width: 70vw;

  }


  .construction__text {
    font-size: 8vw;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    z-index: 1;
  }



}

@media screen and (max-width: 768px) {
  .home__text__box {
    margin-top: -14vh;
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 30vh;

  }

  .home__title {
    color: white;
    font-weight: bold;
    font-size: 12vw;
    font-family: sans-serif;
  }

  .home__slogan {
    color: white;
    font-weight: bold;
    margin-top: 0.5rem;
    font-size: 4vw;
    font-family: sans-serif;
  }

  .construction__box {
    margin-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    width: 80vw;

  }


  .construction__text {
    font-size: 8vw;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    z-index: 1;
  }

}

@media screen and (max-width: 550px) {
  .home__text__box {
    margin-top: 0vh;
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 30vh;

  }

  .home__title {
    color: white;
    font-weight: bold;
    font-size: 12vw;
    font-family: sans-serif;
  }

  .home__slogan {
    color: white;
    font-weight: bold;
    margin-top: 0.5rem;
    font-size: 4vw;
    font-family: sans-serif;
  }

  .construction__box {
    margin-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    width: 70vw;

  }

  .construction__text {
    font-size: 8vw;
    font-family: sans-serif;
    font-weight: bold;
    color: white;
    z-index: 1;
  }

}